export const LOGIN_SUCCESS = 'Login successful'
export const LOGIN_SUCCESS_MESSAGE = 'You have successfully logged in!'
export const SESSION_EXPIRED_MESSAGE = 'Session expired'
export const SESSION_EXPIRED_MESSAGE_DESC = 'Your session expired. Please sign in again'
export const SUCCESS = 'Success!'
export const EDIT_SUCCESS_MESSAGE = 'Successfully edited'
export const ADD_SUCCESS_MESSAGE = 'Successfully added'
export const DELETE_SUCCESS_MESSAGE = 'Deleted successfully'
export const FAILED = 'FAILED'

export const ROLES = {
  superAdmin: 'admin',
  careersAdmin: 'careers-admin',
  appointmentsAdmin: 'appointments-admin',
}
export const LINKS = {
  add_service: '/services/add-service',
  services: '/services',
  gallery_list: '/gallery',
  add_gallery: '/gallery/add-gallery',
  doctors: '/doctors',
  add_doctor: '/doctors/add-doctor',
  blogs: '/blogs',
  add_blog: '/blogs/add-blog',
  comments: '/testimonials',
  add_comment: '/testimonials/add-testimonial',
  careers: '/careers',
  add_career: '/careers/add-career',
  add_department: '/departments/add-department',
  add_seo: '/seo/add-seo'
}

export const API_URL = {}
