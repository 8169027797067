import firebase from 'firebase/app'
import { notification } from 'antd'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyAE5G0RI2LwzwTBizhJbnRKIKbiXQIA1dY',
  authDomain: 'cleanui-72a42.firebaseapp.com',
  databaseURL: 'https://cleanui-72a42.firebaseio.com',
  projectId: 'cleanui-72a42',
  storageBucket: 'cleanui-72a42.appspot.com',
  messagingSenderId: '583382839121',
}

const firebaseApp = firebase.initializeApp(firebaseConfig)
const firebaseAuth = firebase.auth
export default firebaseApp

export async function loginSocial(email, accessToken) {
  // console.log('in loginSocial services')
  const loginData = {
    email,
    accessToken,
  }
  const loginResponse = await fetch('/api/users/login_google', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(loginData),
  })

  const resJSON = await loginResponse.json()
  // console.log(loginResponse)
  console.log(resJSON)
  if (loginResponse.ok) {
    setToken(resJSON.token)
    return true
  }
  return notification.warning({
    message: loginResponse.status,
    description: resJSON.message,
  })
}

export async function login1(email, password) {
  // console.log('login1')
  const loginData = {
    email,
    password,
  }
  try {
    // console.log(process.env.API_URL_DEVELOPMENT, process.env.API_URL_PRODUCTION, process.env.NODE_ENV)
    const loginResponse = await fetch(`/api/users/login`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loginData),
    })
    // console.log(loginResponse.status)
    const resJSON = await loginResponse.json()
    // console.log(resJSON)
    if (loginResponse.status === 200) {
      // console.log('setting token', resJSON.token)
      setToken(resJSON.token)
      notification.success({
        message: 'You are logged in!',
      })
      return true
    }
    notification.warning({
      message: 'Login failed!',
      description: 'Please check your login details and try again',
    })
    return false
  } catch (err) {
    return notification.warning({
      message: err.code,
      description: err.message,
    })
  }
}

const setToken = token => {
  localStorage.setItem('token', token)
}
const getToken = () => {
  return localStorage.getItem('token')
}
const removeToken = () => {
  localStorage.removeItem('token')
}
// const isTokenExpired = token => {
//   // console.log(token.exp, Date.now())
//   if (token.exp < Date.now() / 1000) return true
//   return false
// }

export async function login(email, password) {
  const loginData = {
    email: 'sumi@gmail.com',
    password: '1234',
  }
  // console.log(JSON.stringify(loginData))
  try {
    const a = await fetch('/api/users/login', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loginData),
    })
    // console.log(typeof a.status)
    const resJSON = await a.json()
    console.log(resJSON)
    // if (a.status === 200) {
    //   return {
    //     data: resJSON
    //   }
    // }
    // notification.warning({
    //   message: a.status,
    //   description: resJSON.message
    // })
  } catch (err) {
    notification.warning({
      message: err.code,
      description: err.message,
    })
  }
  return firebaseAuth()
    .signInWithEmailAndPassword(email, password)
    .then(() => true)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function currentAccountJwt() {
  const token = getToken()
  // console.log(token)
  try {
    if (!token) {
      return null
    }
    const response = await fetch('/api/users/verify', {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
    const json = await response.json()
    if (response.ok) {
      return json
    }
    logoutJwt()
    notification.error({
      message: 'Logged out!',
      // description: 'Invalid credentials!',
    })
  } catch (error) {
    notification.error({
      message: 'Error!',
      description: 'Please check your credentials and try again',
    })
  }
  return null
}

export async function currentAccount() {
  let userLoaded = false
  function getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(firebaseAuth.currentUser)
      }
      const unsubscribe = auth.onAuthStateChanged(user => {
        userLoaded = true
        unsubscribe()
        resolve(user)
      }, reject)
    })
  }
  return getCurrentUser(firebaseAuth())
}

export async function logout() {
  return firebaseAuth()
    .signOut()
    .then(() => true)
}

export async function logoutJwt() {
  removeToken()
  return true
}

export async function getNotifications(id) {
  try {
    const res = await fetch(`/api/notifications/${id}`)
    console.log(res)
    const json = await res.json()
    if (!res.ok) {
      throw new Error(json.message || 'Error retreiving notifications')
    }
    const { count, data } = json
    if (count === 0 || !data || data.length === 0) {
      return { count: 0 }
    }
    return json
  } catch (error) {
    console.error(error)
    notification.warning({
      message: error.message,
    })
    return null
  }
}
