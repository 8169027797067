import actions from './actions'

const initialState = {
  loading: false,
  data: null,
  error: null,
  redirect: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.FETCH_ERROR:
      return { ...state, loading: false, error: action.payload, data: null }
    case actions.FETCH_SUCCESS:
      return { ...state, data: action.payload, loading: false }
    default:
      return state
  }
}
