import { LOCATION_CHANGE } from 'connected-react-router'
import actions from './actions'

export const initialState = {
  token: '',
  id: '',
  name: '',
  role: '',
  email: '',
  avatar: '',
  authorized: false,
  loading: false,
  notifications: {
    count: 0,
    appointment: [],
    resume: [],
  },
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case LOCATION_CHANGE:
      return { ...state, ...action.payload }

    default:
      return state
  }
}

export const getUser = state => {
  return state
}
