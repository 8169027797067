/* eslint-disable react/jsx-indent */
/* eslint-disable no-underscore-dangle */
import React from 'react'
import { Menu, Dropdown, Badge } from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getFormattedDate } from 'utils'
// import { ROLES } from '_constants'
import styles from './style.module.scss'

const mapStateToProps = ({ user }) => {
  const { notifications, role } = user
  const { count, data } = notifications
  console.log(notifications)

  return {
    count,
    notifications: data,
    role,
  }
}
class HomeMenu extends React.Component {


  render() {
    console.log(this.props)

    const { count } = this.props
    const { notifications, role } = this.props // setState?
    console.log(count, role)

    // console.log(constants.ROLES.superAdmin)
    // console.log(constants.ROLES.careersAdmin)
    // console.log(constants.ROLES.appointmentsAdmin)

    let notifRender = (
      <Menu.Item className={styles.item}>
        <div className={styles.inner}>
          <div className={styles.title}>No new notifications</div>
        </div>
      </Menu.Item>
    )

    if (notifications && notifications.length > 0) {
      notifRender = notifications
        .filter(i => i.typeId !== null && typeof i.typeId !== 'undefined') // check if name, speciality null; skip
        .map(i => {
          if (i.onModel === 'Appointment') {
            return (
              <Menu.Item key={i._id} className={styles.item}>
                <i className={`${styles.icon} icmn-book`} />
                <div className={styles.inner}>
                  <div className={styles.title}>
                    <Link
                      to={{
                        // issue
                        pathname: `/appointments/${i.typeId._id}`,
                        state: { fromNotification: true, notifId: i._id },
                      }}
                    >
                      Appointment <span className="badge badge-danger">New</span>
                    </Link>
                  </div>
                  <div className={styles.descr}>
                    <strong>Doctor:</strong> {`${i.typeId.doctor.name},${i.typeId.speciality.name}`}
                  </div>
                  <div className={styles.descr}>
                    <strong>Date:</strong>
                    {` ${getFormattedDate(i.typeId.dateAppointment)}`}
                  </div>
                </div>
              </Menu.Item>
            )
          }
          if (i.onModel === 'Resume') {
            return (
              <Menu.Item key={i._id} className={styles.item}>
                <i className={`${styles.icon} icmn-address-book`} />
                <div className={styles.inner}>
                  <div className={styles.title}>
                    <Link
                      to={{
                        pathname: `/careers/resumes/${i.typeId._id}`,
                        state: { fromNotification: true, notifId: i._id },
                      }}
                    >
                      Resume <span className="badge badge-danger">New</span>
                    </Link>
                  </div>
                  <div className={styles.descr}>
                    <strong>Title:</strong> {`${i.typeId.careerListing.title}`}
                  </div>
                </div>
              </Menu.Item>
            )
          }
          return ''
        })
    }

    const menu = (
      <Menu selectable={false} className={styles.activity}>
        {notifRender}
      </Menu>
    )
    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <Badge count={count}>
          <div className={styles.dropdown}>
            <i className="icmn-home topbar__dropdownIcon" />
          </div>
        </Badge>
      </Dropdown>
    )
  }
}

export default connect(mapStateToProps)(HomeMenu)
