/* eslint-disable import/prefer-default-export */

// console.log(process.env.API_URL_DEVELOPMENT, process.env.API_URL_PRODUCTION)

export const baseUrl =
  process.env.NODE_ENV === 'development'
    ? process.env.API_URL_DEVELOPMENT
    : process.env.API_URL_PRODUCTION;


export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
