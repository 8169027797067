import fetch from 'isomorphic-unfetch'

export default async function(...args) {
  try {
    const response = await fetch(...args)
    if (response.ok) return await response.json()
  } catch (err) {
    const error = new Error(err.statusText)
    error.status = err.status
    // console.log(error)
    // notification.error({
    //   message: err.status,
    //   description: err.message,
    // })
  }
  return null
}

export async function callApi(...args) {
  try {
    const response = await fetch(...args)
    console.log(response)
    if (response.ok) {
      const a = await response.json()
      console.log(a)
      return a
    }
    if (!response.ok) {
      const a = await response.json()
      console.log(a)
      throw new Error(a.message || response.statusText)
    }
  } catch (err) {
    throw err
  }
  return null
}
