import { notification } from 'antd'

export async function addPageForm(values) {
  // console.log('pageForm service', values)
  const {
    priorityOrder,
    title,
    status,
    mainImage,
    content,
    pageTitle,
    pageDescription,
    pageKeywords,
    imageTag,
  } = values
  // console.log(values)

  const formData = new FormData()
  formData.append('title', title)
  formData.append('imageTag', imageTag)
  formData.append('priorityOrder', priorityOrder)
  formData.append('status', status)

  // const compressed = await compress(mainImage[0].originFileObj, 366, 350)

  formData.append('image', mainImage[0].originFileObj)
  // formData.append('image', compressed)
  formData.append('content', content)
  formData.append('pageTitle', pageTitle)
  formData.append('pageDescription', pageDescription)
  formData.append('pageKeywords', pageKeywords)

  const url = '/api/pages'
  const options = {
    method: 'POST',
    body: formData,
  }
  try {
    // setSubmitting(true)
    const response = await fetch(url, options)
    if (!response.ok) {
      const err = new Error(response.statusText)
      err.status = response.status
      throw err
    }
    const responseJSON = await response.json()
    // setSubmitting(false)
    notification.success({
      message: 'Success!',
      description: 'Added successfully',
    })
    // console.log(response, responseJSON)
    if (responseJSON.data) {
      return responseJSON.data
    }
    return null
  } catch (err) {
    // console.log(err)
    notification.error({
      message: err.status,
      description: err.message,
    })
    return null
  }
}
export async function editPageForm(values, id) {
  // console.log('in edit page form service', values)
  const {
    title,
    content,
    status,
    pageTitle,
    pageDescription,
    priorityOrder,
    pageKeywords,
    mainImage,
    imageTag,
  } = values
  // console.log(values)

  const formData = new FormData()
  formData.append('title', title)
  formData.append('imageTag', imageTag)
  formData.append('priorityOrder', priorityOrder)
  formData.append('status', status)
  formData.append('image', mainImage[0].originFileObj || null)
  formData.append('content', content)
  formData.append('pageTitle', pageTitle)
  formData.append('pageDescription', pageDescription)
  formData.append('pageKeywords', pageKeywords)

  const url = `/api/pages/${id}`
  const options = {
    method: 'PATCH',
    body: formData,
  }
  try {
    // setSubmitting(true)
    const response = await fetch(url, options)
    if (!response.ok) {
      const err = new Error(response.statusText)
      err.status = response.status
      throw err
    }
    const responseJSON = await response.json()
    // setSubmitting(false)
    // console.log(response, responseJSON)
    if (responseJSON.data) {
      notification.success({
        message: 'Success!',
        description: 'Edited successfully',
      })
      return responseJSON.data
    }
    return null
  } catch (err) {
    // console.log(err)
    notification.error({
      message: err.message,
      description: err.message,
    })
  }
  return null
}

export async function getPage(id) {
  // console.log('in getPage service id:', id)
  const url = `/api/pages/${id}`
  const options = {
    method: 'GET',
  }
  try {
    const response = await fetch(url, options)
    if (!response.ok) {
      const err = new Error(response.statusText)
      err.status = response.status
      throw err
    }
    const responseJSON = await response.json()
    // console.log(response, responseJSON)
    if (responseJSON.data) {
      return responseJSON.data
    }
  } catch (err) {
    // console.log(err)
    notification.error({
      message: 'Error!',
      description: err.message,
    })
  }
  return null
}

export async function add() {
  return null
}
