import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import {
  login,
  login1,
  loginSocial,
  logoutJwt,
  currentAccount,
  getNotifications,
  currentAccountJwt,
  logout,
} from 'services/user'
import {
  getLeftMenuDataSeller,
  getLeftMenuData,
  getTopMenuData,
  getCareersMenuData,
  getAppointmentsMenuData,
} from 'services/menu'
import * as constants from '_constants'
import actions from './actions'
import { initialState } from './reducers'

export function* LOGIN1({ payload }) {
  // console.log('in jwt login')
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(login1, email, password)
  // console.log(success)
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
  }
  return null
}

export function* LOGIN({ payload }) {
  // console.log('in firebase login ')
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(login, email, password)
  // console.log(success)
  if (success) {
    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in to Clean UI React Admin Template!',
    })
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
  }
}

export function* LOGIN_SOCIAL({ payload }) {
  // console.log('in social login ')
  // console.log(payload)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const {
    name,
    email,
    imageUrl: avatar,
    // googleId,
    accessToken,
  } = payload
  const response = yield call(loginSocial, email, accessToken)
  // const { email, password } = payload
  if (response) {
    // console.log(response)
    notification.success({
      message: constants.LOGIN_SUCCESS,
      description: constants.LOGIN_SUCCESS_MESSAGE,
    })
    const { userId: id, role, phone } = response
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        email,
        role,
        name,
        phone,
        avatar,
        authorized: true,
      },
    })
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT_JWT',
    })
  }
}

export function* GET_NOTIFICATIONS_OLD(id, role) {
  const notifications = yield call(getNotifications, id)
  console.log(notifications)
  if (notifications) {
    // console.log('no new notifications')
    const { data, count } = notifications
    if (count === 0) {
      return console.log('no new notifications')
    }
    const appointment = data.filter(i => i.onModel === 'Appointment')
    const resume = data.filter(i => i.onModel === 'Resume')

    let notifs = {}
    switch (role) {
      case constants.ROLES.superAdmin:
        notifs = {
          appointment,
          resume,
        }
        break
      case constants.ROLES.careersAdmin:
        notifs = {
          resume,
        }
        break
      case constants.ROLES.appointmentsAdmin:
        notifs = {
          appointment,
        }
        break
      default:
        notifs = {}
    }

    yield put({
      type: 'user/SET_STATE',
      payload: {
        notifications: {
          count,
          ...notifs,
        },
      },
    })
    // yield put({
    //   type:'user/SET_NOTIFICATIONS',
    //   payload:
    // })
  }

  // yield put({
  //   type:'user/SET_NOTIFICATIONS'
  //   payload:
  // })
}

export function* GET_NOTIFICATIONS(
  id,
  // role
) {
  const notifications = yield call(getNotifications, id)
  console.log(notifications)
  if (notifications) {
    const { data, count } = notifications
    if (count === 0) {
      return console.log('no new notifications')
    }

    const notifs = [...data]
    yield put({
      type: 'user/SET_STATE',
      payload: {
        notifications: {
          count,
          data: notifs,
        },
      },
    })
    // yield put({
    //   type:'user/SET_NOTIFICATIONS',
    //   payload:
    // })
  }

  // yield put({
  //   type:'user/SET_NOTIFICATIONS'
  //   payload:
  // })
}

export function* GET_MENU_DATA(role) {
  let menuLeftData

  if (role === constants.ROLES.superAdmin) {
    console.log('setting admin menu')
    menuLeftData = yield call(getLeftMenuData)
  } else if (role === constants.ROLES.careersAdmin) {
    console.log('setting careers menu')
    menuLeftData = yield call(getCareersMenuData)
  } else if (role === constants.ROLES.appointmentsAdmin) {
    console.log('setting appointment menu')
    menuLeftData = yield call(getAppointmentsMenuData)
  } else {
    console.log('setting normal menu')
    menuLeftData = yield call(getLeftMenuDataSeller)
  }
  // const menuLeftData = role === 'admin' ? yield call(getLeftMenuData) : yield call(getLeftMenuDataSeller)
  const menuTopData = yield call(getTopMenuData)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuLeftData,
      menuTopData,
    },
  })
}

export function* LOAD_CURRENT_ACCOUNT_JWT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(currentAccountJwt)
  // console.log(response)
  if (response) {
    // console.log(response)
    const { userId: id, email, role, name, phoneNo: phone } = response
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        email,
        role,
        name,
        phone,
        authorized: true,
      },
    })

    yield GET_MENU_DATA(role)
    yield GET_NOTIFICATIONS(id, role)
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        authorized: false,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_CURRENT_ACCOUNT() {
  // console.log('in normal current account')
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(currentAccount)
  if (response) {
    const { uid: id, email, photoURL: avatar } = response
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        name: 'Administrator',
        email,
        avatar,
        role: 'admin',
        authorized: true,
      },
    })
    yield put({
      type: 'menu/SET_STATE',
    })
    const menuLeftData = yield call(getLeftMenuDataSeller)
    const menuTopData = yield call(getTopMenuData)
    yield put({
      type: 'menu/SET_STATE',
      payload: {
        menuLeftData,
        menuTopData,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield call(logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
      notifications: null,
    },
  })
}
export function* LOGOUT_JWT() {
  console.log('logout setting state')
  yield put({
    type: 'user/SET_STATE',
    payload: {
      ...initialState,
    },
  })
  yield call(logoutJwt)
}

export function* MOCK_LOGIN() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '123',
      name: 'Administrator',
      email: 'admin@gmail.com',
      role: 'admin',
      authorized: true,
    },
  })
}

export function* MOCK_LOAD() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '123',
      email: 'admin@gmail.com',
      role: 'admin',
      name: 'Administrator',
      phone: '123',
      authorized: true,
    },
  })
  yield GET_MENU_DATA('admin')
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN1),
    takeEvery(actions.LOGIN_SOCIAL, LOGIN_SOCIAL),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT_JWT),
    takeEvery(actions.LOGOUT, LOGOUT_JWT),
    LOAD_CURRENT_ACCOUNT_JWT(), // run once on app load to check user auth
  ])
}
