const actions = {
  SET_STATE: 'blogs/SET_STATE',
  ADD_BLOG: 'blogs/ADD_BLOG',
  EDIT_BLOG: 'blogs/EDIT_BLOG',
  FETCH_ERROR: 'blogs/FETCH_ERROR',
  FETCH_SUCCESS: 'blogs/FETCH_SUCCESS',
  GET_BLOG: 'blogs/GET_BLOG',
}

export default actions
