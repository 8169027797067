import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import page from './page/sagas'
import blog from './blog/sagas'
import comment from './comment/sagas'
import service from './service/sagas'

export default function* rootSaga() {
  yield all([user(), menu(), settings(), page(), blog(), service(), comment()])
}
