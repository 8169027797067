import React from 'react'
import ProfileMenu from './ProfileMenu'
import styles from './style.module.scss'
import HomeMenu from './HomeMenu'

class TopBar extends React.Component {
  render() {
    return (
      <div className={styles.topbar}>
        <div className="mr-8">
          <HomeMenu />
        </div>
        <div className="ml-auto">
          <ProfileMenu />
        </div>
      </div>
    )
  }
}

export default TopBar
