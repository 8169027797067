import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import page from './page/reducers'
import blog from './blog/reducers'
import service from './service/reducers'
import comment from './comment/reducers'
// import attributes from './attributes/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    page,
    blog,
    service,
    comment,
    // attributes
  })
