/* eslint-disable no-global-assign */
/* eslint-disable func-names */
export const GlobalDebug = (function() {
  const savedConsole = console
  return function(debugOn = true, suppressAll) {
    const suppress = suppressAll || false

    if (debugOn === false) {
      console = {}
      console.log = function() {}
      if (suppress) {
        console.info = function() {}
        console.warn = function() {}
        console.error = function() {}
      } else {
        console.info = savedConsole.info
        console.warn = savedConsole.warn
        console.error = savedConsole.error
      }
    } else {
      console = savedConsole
    }
  }
})()

export function getFormattedDate(dateJSON) {
  const date = new Date(dateJSON)
  const DD = (date.getDate() < 10 ? '0' : '') + date.getDate()
  const MM = (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1)
  const YYYY = date.getFullYear()

  return `${DD}-${MM}-${YYYY}`
}

export function getFormattedTime(dateJSON) {
  const date = new Date(dateJSON)
  const HH = (date.getHours() < 10 ? '0' : '') + date.getHours()
  const mm = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
  const ss = (date.getSeconds() < 10 ? '0' : '') + date.getSeconds()

  return `${HH}:${mm}:${ss}`
}

export function isPushNotificationSupported() {
  return 'serviceWorker' in navigator && 'PushManager' in window
}

export const unflatten = (array, parent, tree) => {
  tree = typeof tree !== 'undefined' ? tree : []
  parent = typeof parent !== 'undefined' ? parent : { id: 0 }

  const children = array.filter(child => {
    return child.parent === parent.id
  })
  // console.log(children.length)
  if (children.length !== 0) {
    if (parent.id === 0) {
      tree = children
    } else {
      parent.children = children
    }
    children.forEach(child => {
      unflatten(array, child)
    })
  }

  return tree
}

export const validateQty = (rule, value, callback) => {
  const reg = /^-?(0|[1-9][0-9]*)?$/
  // console.log(value)
  // // console.log((Number(value) % 1 === 0))
  // if (Number(value) === value && value % 1 === 0) {
  if ((!Number.isNaN(value) && reg.test(value)) || value === '' || value === '-') {
    //     // props.onChange(changedFields);
    callback()
    return
  }
  callback('Not a valid quantity!')
}

export const validateEndDate = (rule, value, callback) => {
  callback()
}

export const isEmpty = val => {
  if (val === '' || val === null || val === undefined) return true
  if (val.constructor === Object && Object.entries(val).length === 0) return true
  if (val.constructor === Array && val.length === 0) return true
  return false
}

export const isEmptyUndef = val => {
  // console.log(`*${val}*`, typeof val, (val === "undefined" || isEmpty(val)))
  return val === 'undefined' || isEmpty(val)
}

export const trimString = (text, length) => {
  return text.toString().slice(0, length)
}

export const timeout = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
    lg: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
    lg: { span: 12 },
    // lg: { span: 18 },
  },
}

export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      // span: 16,
      // offset: 8,
      span: 16,
      offset: 4,
    },
  },
}

function getImageSize(imageURL, callback) {
  // Create image object to ascertain dimensions.
  const image = new Image()

  // Get image data when loaded.
  image.onload = function() {
    // No callback? Show error.
    if (!callback) {
      console.log(`Error getting image size: no callback. Image URL: ${imageURL}`)

      // Yes, invoke callback with image size.
    } else {
      callback(this.naturalWidth, this.naturalHeight)
    }
  }
  // Load image.
  image.src = imageURL
}

export function getImageDimensions(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      getImageSize(reader.result, (imageWidth, imageHeight) => {
        // console.log(imageWidth, imageHeight)
        resolve({ imageWidth, imageHeight })
      })
      // console.log(reader.result);
    }
    reader.onerror = error => reject(error)
  })
}

export const getBaseName = path => {
  // console.log(path)
  if (path) {
    const parsed = path.split('/')
    return parsed[parsed.length - 1]
  }
  return ''
}

export function compress(file, type, max) {
  // const width = 500
  // const height = 300
  console.log('in compress')
  const fileName = file.name
  const reader = new FileReader()
  reader.readAsDataURL(file)
  return new Promise(res => {
    reader.onload = event => {
      const img = new Image()
      img.src = event.target.result
      img.onload = function() {
        const elem = document.createElement('canvas')
        const { width, height } = calculateAspectRatioFit(
          this.naturalWidth,
          this.naturalHeight,
          type === 'width' ? 'width' : 'height',
          max,
        )
        console.log(width, height)
        elem.width = width
        elem.height = height
        const ctx = elem.getContext('2d')
        // img.width and img.height will contain the original dimensions
        ctx.drawImage(img, 0, 0, width, height)
        ctx.canvas.toBlob(
          blob => {
            const fileR = new File([blob], fileName, {
              type: 'image/jpeg',
              lastModified: Date.now(),
            })
            console.log('will resolve', fileR)
            res(fileR)
          },
          'image/jpeg',
          1,
        )
      }
      reader.onerror = error => console.log(error)
    }
  })
}

/**
 * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
 * images to fit into a certain area.
 *
 * @param {Number} srcWidth width of source image
 * @param {Number} srcHeight height of source image
 * @param {Number} maxWidth maximum available width
 * @param {Number} maxHeight maximum available height
 * @return {Object} { width, height }
 */
export function calculateAspectRatioFitA(srcWidth, srcHeight, maxWidth, maxHeight) {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight)

  return { width: srcWidth * ratio, height: srcHeight * ratio }
}

export function calculateAspectRatioFit(srcWidth, srcHeight, type, max) {
  let newWidth = 0
  let newHeight = 0
  console.log(type, max)
  if (type === 'height') {
    newWidth = (srcWidth / srcHeight) * max
    newHeight = max
  } else {
    newHeight = (srcHeight / srcWidth) * max
    newWidth = max
  }
  return { width: newWidth, height: newHeight }
}

// export function resize() {
//   let reader = new FileReader()
//   reader.onload = readerEvent => {
//     let image = new Image()
//     image.onload = (imageEvent) => {
//       // Resize the image
//       let canvas = document.createElement('canvas'),
//         let max_size = 1200,
//         let width = image.width,
//         height = image.height
//       if (width > height) {
//         if (width > max_size) {
//           height *= max_size / width
//           width = max_size
//         }
//       } else {
//         if (height > max_size) {
//           width *= max_size / height
//           height = max_size
//         }
//       }
//       canvas.width = width
//       canvas.height = height
//       canvas.getContext('2d').drawImage(image, 0, 0, width, height)
//       resizedImage = canvas.toDataURL('image/jpeg')
//     }
//     image.src = readerEvent.target.result
//   }
//   // reader.readAsDataURL(file);
//   // }
// }

// Object.byString = function(o, s) {
//   s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
//   s = s.replace(/^\./, '');           // strip a leading dot
//   var a = s.split('.');
//   for (var i = 0, n = a.length; i < n; ++i) {
//       var k = a[i];
//       if (k in o) {
//           o = o[k];
//       } else {
//           return;
//       }
//   }
//   return o;
// }
