import { LINKS } from '_constants'

export async function getCareersMenuData() {
  console.log('in getCareersMenuData')
  return [
    {
      title: 'Career',
      key: 'career',
      icon: 'icmn icmn-address-book',
      url: '/careers',
    },
    {
      title: 'Add Career',
      key: 'add-career',
      icon: 'icmn icmn-plus',
      url: '/careers/add-career',
    },
    {
      title: 'Uploaded Resumes',
      key: 'career-posts',
      icon: 'icmn icmn-upload',
      url: '/careers/resumes',
    },
  ]
}
export async function getAppointmentsMenuData() {
  return [
    {
      title: 'Appointments',
      key: 'appointments',
      icon: 'icmn icmn-book',
      url: '/appointments',
    },
  ]
}

export async function getLeftMenuData() {
  console.log('settng left menu data')
  return [
    // Catalog
    {
      title: 'Pages',
      key: 'pages',
      icon: 'icmn icmn-newspaper',
      children: [
        {
          title: 'Pages List',
          key: 'pages-list',
          url: '/pages',
        },
        {
          title: 'Add Page',
          key: 'add-page',
          url: '/pages/add-page',
        },
      ],
    },
    // Services
    {
      title: 'Services',
      key: 'services',
      icon: 'icmn icmn-stack',
      children: [
        {
          title: 'Services List',
          key: 'services-list',
          url: LINKS.services,
        },
        {
          title: 'Add Service',
          key: 'add-service',
          url: LINKS.add_service,
        },
      ],
    },
    // Gallery
    {
      title: 'Gallery',
      key: 'gallery',
      icon: 'icmn icmn-images',
      children: [
        {
          title: 'Gallery List',
          key: 'gallery-list',
          url: LINKS.gallery_list,
        },
        {
          title: 'Add Gallery',
          key: 'add-gallery',
          url: LINKS.add_gallery,
        },
      ],
    },
    // Doctors
    {
      title: 'Doctors',
      key: 'doctors',
      icon: 'icmn icmn-users',
      children: [
        {
          title: 'Doctors List',
          key: 'doctors-list',
          url: '/doctors',
        },
        {
          title: 'Add Doctor',
          key: 'add-doctor',
          url: '/doctors/add-doctor',
        },
      ],
    },
    {
      title: 'Departments',
      key: 'departments',
      icon: 'icmn icmn-home',
      children: [
        {
          title: 'Departments List',
          key: 'departments-list',
          url: '/departments',
        },
        {
          title: 'Add Department',
          key: 'add-department',
          url: '/departments/add-department',
        },
      ],
    },
    {
      title: 'Blog',
      key: 'blog',
      icon: 'icmn icmn-file-text2',
      children: [
        {
          title: 'Blogs List',
          key: 'blogs-list',
          url: LINKS.blogs,
        },
        {
          title: 'Add Blog',
          key: 'add-blog',
          url: LINKS.add_blog,
        },
      ],
    },
    {
      title: 'Testimonials',
      key: 'testimonials',
      icon: 'icmn icmn-star-half',
      children: [
        {
          title: 'Testimonials list',
          key: 'testimonials-list',
          url: LINKS.comments,
        },
        {
          title: 'Add Testimonial',
          key: 'add-testimonial',
          url: LINKS.add_comment,
        },
        {
          title: 'Videos',
          key: 'testimonial-videos',
          url: `${LINKS.comments}/videos`,
        },
        // {
        //   title: 'Success Stories',
        //   key: 'success-stories',
        //   url: `${LINKS.comments}/success-stories`,
        // },
      ],
    },
    {
      title: 'Career',
      key: 'career',
      icon: 'icmn icmn-address-book',
      children: [
        {
          title: 'Career List',
          key: 'career-list',
          url: '/careers',
        },
        {
          title: 'Add Career',
          key: 'add-career',
          url: '/careers/add-career',
        },
        {
          title: 'Uploaded Resumes',
          key: 'career-posts',
          url: '/careers/resumes',
        },
      ],
    },
    {
      title: 'Photos of the day',
      key: 'photos-day',
      icon: 'icmn icmn-instagram',

      children: [
        {
          title: 'Photos of day',
          key: 'photos-day',
          url: '/photos',
        },
      ],
    },
    {
      title: 'Appointments',
      key: 'appointments',
      icon: 'icmn icmn-book',
      children: [
        {
          title: 'View Appointments',
          key: 'view-appointments',
          url: '/appointments',
        },
      ],
    },
    {
      title: 'SEO',
      key: 'seo',
      icon: 'icmn icmn-search',
      children: [
        {
          title: 'SEO List',
          key: 'seo-list',
          url: '/seo',
        },
        // {
        //   title: 'Add SEO',
        //   key: 'seo-add',
        //   url: '/seo/add-seo',
        // },
      ],
    },
    {
      title: 'SMTP Settings',
      key: 'smtp-settings',
      icon: 'icmn icmn-envelop',
      url: '/smtp-settings'
    },
    // Theme settings
    // {
    //   title: 'ThemeSettings',
    //   key: 'settings',
    //   icon: 'icmn icmn-cog utils__spin-delayed--pseudo-selector',
    // },
  ]
}
export async function getTopMenuData() {
  return [
    // Catalog
    {
      title: 'Pages',
      key: 'pages',
      icon: 'icmn icmn-newspaper',
      children: [
        {
          title: 'Pages List',
          key: 'pages-list',
          url: '/pages',
        },
        {
          title: 'Add Page',
          key: 'add-page',
          url: '/pages/add-page',
        },
      ],
    },
    // Services
    {
      title: 'Services',
      key: 'services',
      icon: 'icmn icmn-stack',
      children: [
        {
          title: 'Services List',
          key: 'services-list',
          url: '/services',
        },
        {
          title: 'Add Service',
          key: 'add-service',
          url: LINKS.add_service,
        },
      ],
    },
    // Gallery
    {
      title: 'Gallery',
      key: 'gallery',
      icon: 'icmn icmn-images',
      children: [
        {
          title: 'Gallery List',
          key: 'gallery-list',
          url: LINKS.gallery_list,
        },
        {
          title: 'Add Gallery',
          key: 'add-gallery',
          url: LINKS.add_gallery,
        },
      ],
    },
    // Doctors
    {
      title: 'Doctors',
      key: 'doctors',
      icon: 'icmn icmn-users',
      children: [
        {
          title: 'Doctors List',
          key: 'doctors-list',
          url: '/doctors',
        },
        {
          title: 'Add Doctor',
          key: 'add-doctor',
          url: '/doctors/add-doctor',
        },
      ],
    },
    {
      title: 'Blog',
      key: 'blog',
      icon: 'icmn icmn-file-text2',
      children: [
        {
          title: 'Blogs List',
          key: 'blogs-list',
          url: '/blog',
        },
        {
          title: 'Add Blog',
          key: 'add-blog',
          url: '/blogs/add-blog',
        },
      ],
    },
    {
      title: 'Testimonials',
      key: 'testimonials',
      icon: 'icmn icmn-star-half',
      children: [
        {
          title: 'Testimonials list',
          key: 'testimonials-list',
          url: '/testimonials',
        },
        {
          title: 'Add Testimonial',
          key: 'add-testimonial',
          url: '/testimonials/add-testimonial',
        },
      ],
    },
    {
      title: 'Career',
      key: 'career',
      icon: 'icmn icmn-address-book',
      children: [
        {
          title: 'Career List',
          key: 'career-list',
          url: '/careers',
        },
        {
          title: 'Add Career',
          key: 'add-career',
          url: '/careers/add-career',
        },
      ],
    },
    {
      title: 'Photos of the day',
      key: 'photos-day',
      icon: 'icmn icmn-address-book',
      children: [
        {
          title: 'Photos of day',
          key: 'photos-day',
          url: '/photos',
        },
      ],
    },
    {
      title: 'Appointments',
      key: 'appointments',
      icon: 'icmn icmn-book',
      children: [
        {
          title: 'View Appointments',
          key: 'view-appointments',
          url: '/appointments',
        },
      ],
    },

    // Theme settings
    // {
    //   title: 'ThemeSettings',
    //   key: 'settings',
    //   icon: 'icmn icmn-cog utils__spin-delayed--pseudo-selector',
    // },

    // {
    //   title: 'Settings',
    //   key: 'settings',
    //   icon: 'icmn icmn-cog utils__spin-delayed--pseudo-selector',
    // },
    // {
    //   title: 'Docs',
    //   key: 'documentation',
    //   url: 'https://docs.cleanuitemplate.com/react/getting-started',
    //   target: '_blank',
    //   icon: 'icmn icmn-books',
    // },
    // {
    //   title: 'Dashboard Alpha',
    //   key: 'dashboardAlpha',
    //   url: '/dashboard/alpha',
    //   icon: 'icmn icmn-home',
    // },
    // {
    //   title: 'AntDesign',
    //   key: 'antComponents',
    //   icon: 'icmn icmn-menu',
    //   url: '/antd',
    // },
  ]
}

export async function getLeftMenuDataSeller() {
  return [
    {
      title: 'Seller Dashboard',
      key: 'dashboardSeller',
      url: '/dashboard/seller',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Payments history',
      key: 'sellerPayments',
      url: '/dashboard/seller',
      icon: 'icmn icmn-credit-card',
    },
    {
      title: 'Sales',
      key: 'sellerSales',
      url: '/dashboard/seller',
      icon: 'icmn icmn-price-tags',
    },
    {
      title: 'Settings',
      key: 'settings',
      icon: 'icmn icmn-cog utils__spin-delayed--pseudo-selector',
    },

    // {
    //   title: 'Docs',
    //   key: 'documentation',
    //   url: 'https://docs.cleanuitemplate.com/react/getting-started',
    //   target: '_blank',
    //   icon: 'icmn icmn-books'
    // },
    // {
    //   title: 'AntDesign',
    //   key: 'antComponents',
    //   icon: 'icmn icmn-menu',
    //   url: '/antd'
    // },
  ]
}
