import {
  all,
  put,
  call,
  takeEvery,
  // select
} from 'redux-saga/effects'
import { addServiceForm, editServiceForm, getService } from 'services/service'
import actions from './actions'

export function* ADD_SERVICE({ payload }) {
  // console.log('in ADD_SERVICE saga', payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const data = yield call(addServiceForm, payload)
  // console.log(data)
  if (data) {
    yield put({
      type: actions.FETCH_SUCCESS,
      payload: data,
    })
    return
  }
  yield put({
    type: actions.FETCH_ERROR,
    payload: 'Error adding service',
  })
}

export function* EDIT_SERVICE({ payload }) {
  // console.log('in EDIT_SERVICE saga', payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const data = yield call(editServiceForm, payload)
  // console.log(data)
  if (data) {
    yield put({
      type: actions.FETCH_SUCCESS,
      payload: data,
    })
    return yield put({
      type: actions.SET_STATE,
      payload: {
        redirect: true,
      },
    })
  }
  yield put({
    type: actions.FETCH_ERROR,
    payload: 'Error updating service',
  })
}

export function* GET_SERVICE({ payload }) {
  // console.log('in GET_SERVICE saga')
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const data = yield call(getService, payload)
  // console.log(data)
  if (data) {
    return yield put({
      type: actions.FETCH_SUCCESS,
      payload: data,
    })
  }
  yield put({
    type: actions.FETCH_ERROR,
    payload: 'Error fetching service',
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD_SERVICE, ADD_SERVICE),
    takeEvery(actions.GET_SERVICE, GET_SERVICE),
    takeEvery(actions.EDIT_SERVICE, EDIT_SERVICE),
  ])
}
