import {
  all,
  put,
  call,
  takeEvery,
  // select
} from 'redux-saga/effects'
import { addPageForm, getPage, editPageForm } from 'services/page'
import actions from './actions'

export function* ADD_PAGE({ payload }) {
  // console.log('in ADD_PAGE saga', payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const data = yield call(addPageForm, payload)
  // console.log(data)
  if (data) {
    return yield put({
      type: actions.FETCH_SUCCESS,
      payload: data,
    })
  }
  yield put({
    type: actions.FETCH_ERROR,
    payload: 'Error adding page',
  })
}

export function* EDIT_PAGE({ payload }) {
  // console.log('in EDIT_PAGE saga', payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const data = yield call(editPageForm, payload)
  // console.log(data)
  if (data) {
    yield put({
      type: actions.FETCH_SUCCESS,
      payload: data,
    })
    yield put({
      type: actions.SET_STATE,
      payload: { redirect: true },
    })
    return
  }
  yield put({
    type: actions.FETCH_ERROR,
    payload: 'Error updating page',
  })
}

export function* GET_PAGE({ payload }) {
  // console.log('in GET_PAGE saga')
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const data = yield call(getPage, payload)
  // console.log(data)
  if (data) {
    return yield put({
      type: actions.FETCH_SUCCESS,
      payload: data,
    })
  }
  yield put({
    type: actions.FETCH_ERROR,
    payload: 'Error fetching page',
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD_PAGE, ADD_PAGE),
    takeEvery('pages/GET_PAGE', GET_PAGE),
    takeEvery(actions.EDIT_PAGE, EDIT_PAGE),
  ])
}
