const actions = {
  SET_STATE: 'comments/SET_STATE',
  ADD_COMMENT: 'comments/ADD_COMMENT',
  EDIT_COMMENT: 'comments/EDIT_COMMENT',
  FETCH_ERROR: 'comments/FETCH_ERROR',
  FETCH_SUCCESS: 'comments/FETCH_SUCCESS',
  GET_COMMENT: 'comments/GET_COMMENT',
}

export default actions
