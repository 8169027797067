const actions = {
  SET_STATE: 'pages/SET_STATE',
  ADD_PAGE: 'pages/ADD_PAGE',
  EDIT_PAGE: 'pages/EDIT_PAGE',
  FETCH_ERROR: 'pages/FETCH_ERROR',
  FETCH_SUCCESS: 'pages/FETCH_SUCCESS',
  GET_PAGE: 'pages/GET_PAGE',
}

export default actions
