import { notification } from 'antd'

export async function addBlogForm(values) {
  // console.log('addBlogForm service', values)

  const formData = await getFormData(values)
  // console.log(values)

  const url = '/api/blogs'
  const options = {
    method: 'POST',
    body: formData,
  }
  try {
    const response = await fetch(url, options)
    if (!response.ok) {
      const err = new Error(response.statusText)
      err.status = response.status
      throw err
    }
    const responseJSON = await response.json()
    notification.success({
      message: 'Success!',
      description: 'Added successfully',
    })
    // console.log(response, responseJSON)
    if (responseJSON.data) {
      return responseJSON.data
    }
    return null
  } catch (err) {
    // console.log(err)
    notification.error({
      message: err.status,
      description: err.message,
    })
  }
  return null
}

async function getFormData({
  title,
  shortDescription,
  content,
  image,
  priorityOrder,
  status,
  pageTitle,
  pageDescription,
  pageKeywords,
  imageTag,
}) {
  console.log(imageTag)
  const formData = new FormData()
  formData.append('title', title)
  formData.append('priorityOrder', priorityOrder)
  formData.append('shortDescription', shortDescription)
  formData.append('content', content)
  formData.append('imageTag', imageTag)
  formData.append('image', image[0].originFileObj)
  formData.append('pageTitle', pageTitle)
  formData.append('pageDescription', pageDescription)
  formData.append('pageKeywords', pageKeywords)
  // let compressedImage = null
  // if (image[0].originFileObj) {
  //   compressedImage = await compress(image[0].originFileObj, 'height', 550)
  // }
  // formData.append('image', compressedImage)
  formData.append('status', status)
  return formData
}

export async function editBlogForm(values) {
  console.log('in editBlogForm service', values)
  const { _id } = values
  const formData = await getFormData(values)
  // console.log('title', formData.get('title'))
  // console.log('shortDescription', formData.get('shortDescription'))
  // console.log('content', formData.get('content'))
  // console.log('image', formData.get('image'))
  // console.log('status', formData.get('status'))
  // console.log('priorityOrder', formData.get('priorityOrder'))

  const url = `/api/blogs/${_id}`
  const options = {
    method: 'PATCH',
    body: formData,
  }
  try {
    // setSubmitting(true)
    const response = await fetch(url, options)
    const responseJSON = await response.json()
    if (!response.ok) {
      const err = new Error(responseJSON || response.statusText)
      err.status = response.status
      throw err
    }
    // setSubmitting(false)
    notification.success({
      message: 'Success!',
      description: 'Edited successfully',
    })
    // console.log(response, responseJSON)
    if (responseJSON.data) {
      return responseJSON.data
    }
    return null
  } catch (err) {
    // console.log(err)
    notification.error({
      message: err.status,
      description: err.message,
    })
  }
  return null
}

export async function getBlog(id) {
  // console.log('in getBlog service id:', id)
  const url = `/api/blogs/${id}`
  const options = {
    method: 'GET',
  }
  try {
    const response = await fetch(url, options)
    const responseJSON = await response.json()
    if (!response.ok) {
      const err = new Error(responseJSON.message || response.statusText)
      err.status = response.status
      throw err
    }
    // console.log(response, responseJSON)
    if (responseJSON.data) {
      return responseJSON.data
    }
    return null
  } catch (err) {
    // console.log(err)
    notification.error({
      message: 'Error!',
      description: err.message,
    })
  }
  return null
}

export async function add() {
  return null
}
