const actions = {
  SET_STATE: 'services/SET_STATE',
  ADD_SERVICE: 'services/ADD_SERVICE',
  EDIT_SERVICE: 'services/EDIT_SERVICE',
  FETCH_ERROR: 'services/FETCH_ERROR',
  FETCH_SUCCESS: 'services/FETCH_SUCCESS',
  GET_SERVICE: 'services/GET_SERVICE',
}

export default actions
