import { notification } from 'antd'

export async function getFormData({
  title,
  status,
  icon,
  shortDescription,
  pageTitle,
  pageDescription,
  pageKeywords,
  content,
  priorityOrder,
  imageTag,
}) {
  const formData = new FormData()
  formData.append('title', title)
  formData.append('imageTag', imageTag)
  formData.append('status', status)
  // let compressedIcon = null;
  // if (icon[0].originFileObj){
  //   compressedIcon = await compress(icon[0].originFileObj, "height", 550)

  // }

  formData.append('icon', icon[0].originFileObj || null)
  // formData.append('icon',compressedIcon)
  formData.append('shortDescription', shortDescription)
  formData.append('content', content)
  formData.append('priorityOrder', priorityOrder)
  formData.append('pageTitle', pageTitle)
  formData.append('pageDescription', pageDescription)
  formData.append('pageKeywords', pageKeywords)
  return formData
}

export async function addServiceForm(values) {
  // console.log('addServiceForm service', values)

  // console.log(values)

  const formData = await getFormData(values)

  const url = `/api/services/`
  const options = {
    method: 'POST',
    body: formData,
  }
  try {
    // setSubmitting(true)
    const response = await fetch(url, options)
    if (!response.ok) {
      const err = new Error(response.statusText)
      err.status = response.status
      throw err
    }
    const responseJSON = await response.json()
    // setSubmitting(false)
    notification.success({
      message: 'Success!',
      description: 'Added successfully',
    })
    // console.log(response, responseJSON)
    if (responseJSON.data) {
      return responseJSON.data
    }
    return null
  } catch (err) {
    // console.log(err)
    notification.error({
      message: err.status,
      description: err.message,
    })
  }
  return null
}

export async function editServiceForm(values, id) {
  // console.log('in editServiceForm service', values)

  // console.log(values)

  const formData = await getFormData(values)

  const url = `/api/services/${id}`
  const options = {
    method: 'PATCH',
    body: formData,
  }
  try {
    // setSubmitting(true)
    const response = await fetch(url, options)
    if (!response.ok) {
      const err = new Error(response.statusText)
      err.status = response.status
      throw err
    }
    const responseJSON = await response.json()
    // setSubmitting(false)
    notification.success({
      message: 'Success!',
      description: 'Edited successfully',
    })
    // console.log(response, responseJSON)
    if (responseJSON.data) {
      return responseJSON.data
    }
    return null
  } catch (err) {
    // console.log(err)
    notification.error({
      message: err.status,
      description: err.message,
    })
  }
  return null
}

export async function getService(id) {
  // console.log('in getService id:', id)
  const url = `/api/services/${id}`
  const options = {
    method: 'GET',
  }
  try {
    const response = await fetch(url, options)
    if (!response.ok) {
      const err = new Error(response.statusText)
      err.status = response.status
      throw err
    }
    const responseJSON = await response.json()
    // console.log(response, responseJSON)
    if (responseJSON.data) {
      return responseJSON.data
    }
  } catch (err) {
    // console.log(err)
    notification.error({
      message: 'Error!',
      description: err.message,
    })
  }
  return null
}

export async function add() {
  return null
}
