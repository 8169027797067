/* eslint-disable no-else-return */
import React, { Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
// import Loadable from 'react-loadable'
import { connect } from 'react-redux'
import Loader from 'components/LayoutComponents/Loader'
import ErrorBoundary from 'components/CustomComponents/ErrorBoundary'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'
import { LINKS, ROLES } from '_constants'
import PrivateRoute from './PrivateRoute'

function componentLoader(lazyComponent, attemptsLeft) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
        }, 1500);
      });
  });
}

const loadable = (loader) => React.lazy(()=>componentLoader(loader,5)) // added
// Loadable({
//   loader,
//   // delay: false,
//   loading: () => <Loader />,
//   delay: 200
// })

const routes = [
  // Pages
  {
    path: '/pages/add-page',
    component: loadable(() => import('pages/pages/forms')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  {
    path: '/pages',
    component: loadable(() => import('pages/pages')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  {
    path: '/pages/:id',
    component: loadable(() => import('pages/pages/forms')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },

  // Services
  {
    path: LINKS.services,
    component: loadable(() => import('pages/services')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  {
    path: LINKS.add_service,
    component: loadable(() => import('pages/services/forms')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  {
    path: `${LINKS.services}/:id`,
    component: loadable(() => import('pages/services/forms')),
    exact: true,
  },
  // Gallery

  {
    path: LINKS.gallery_list,
    component: loadable(() => import('pages/gallery')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  {
    path: LINKS.add_gallery,
    component: loadable(() => import('pages/gallery/forms')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  {
    path: `${LINKS.gallery_list}/:id`,
    component: loadable(() => import('pages/gallery/forms')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  // Doctors
  {
    path: LINKS.doctors,
    component: loadable(() => import('pages/doctors')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  {
    path: LINKS.add_doctor,
    component: loadable(() => import('pages/doctors/forms')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  {
    path: `${LINKS.doctors}/:id`,
    component: loadable(() => import('pages/doctors/forms')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  // Blogs
  {
    path: LINKS.blogs,
    component: loadable(() => import('pages/blogs')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  {
    path: LINKS.add_blog,
    component: loadable(() => import('pages/blogs/forms')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  {
    path: `${LINKS.blogs}/:id`,
    component: loadable(() => import('pages/blogs/forms')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  // Testimonials
  {
    path: LINKS.comments,
    component: loadable(() => import('pages/comments')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  {
    path: LINKS.add_comment,
    component: loadable(() => import('pages/comments/forms')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  {
    path: `${LINKS.comments}/videos`,
    component: loadable(() => import('pages/comments/Videos')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  // {
  //   path: `${LINKS.comments}/success-stories`,
  //   component: loadable(() => import('pages/success-stories')),
  //   exact: true,
  //   authorize: {
  //     role: [ROLES.superAdmin],
  //     redirect: '/',
  //   },
  // },
  {
    path: `${LINKS.comments}/:id`,
    component: loadable(() => import('pages/comments/forms')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  {
    path: `/appointments`,
    component: loadable(() => import('pages/appointments')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin, ROLES.appointmentsAdmin],
      redirect: '/',
    },
  },
  {
    path: `/appointments/edit/:id`,
    component: loadable(() => import('pages/appointments/forms')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin, ROLES.appointmentsAdmin],
      redirect: '/',
    },
  },
  {
    path: `/appointments/:id`,
    component: loadable(() => import('pages/appointments/view-appointment')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin, ROLES.appointmentsAdmin],
      redirect: '/',
    },
  },

  // Careers
  {
    path: LINKS.careers,
    component: loadable(() => import('pages/career')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin, ROLES.careersAdmin],
      redirect: '/',
    },
  },
  {
    path: `/careers/resumes`,
    component: loadable(() => import('pages/resumes')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin, ROLES.careersAdmin],
      redirect: '/',
    },
  },
  {
    path: `/careers/resumes/:id`,
    component: loadable(() => import('pages/resumes/view-resume')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin, ROLES.careersAdmin],
      redirect: '/',
    },
  },
  {
    path: LINKS.add_career,
    component: loadable(() => import('pages/career/forms')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin, ROLES.careersAdmin],
      redirect: '/',
    },
  },
  {
    path: `${LINKS.careers}/:id`,
    component: loadable(() => import('pages/career/forms')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin, ROLES.careersAdmin],
      redirect: '/',
    },
  },
  {
    path: `/photos`,
    component: loadable(() => import('pages/photo-of-the-day')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  {
    path: `/departments`,
    component: loadable(() => import('pages/departments')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  {
    path: `/departments/add-department`,
    component: loadable(() => import('pages/departments/forms')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  {
    path: `/departments/:id`,
    component: loadable(() => import('pages/departments/forms')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  {
    path: `/seo`,
    component: loadable(() => import('pages/seo')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  {
    path: `/seo/add-seo`,
    component: loadable(() => import('pages/seo/forms')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  {
    path: `/seo/:id`,
    component: loadable(() => import('pages/seo/forms')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
  },
  {
    path: `/smtp-settings`,
    component: loadable(() => import('pages/smtp-settings')),
    exact: true,
    authorize: {
      role: [ROLES.superAdmin],
      redirect: '/',
    },
    breadcrumb: ['Home', 'SMTP Settings'],
  },

  // Test form
  // status dropdown, main image choose
]

@connect(({ user }) => ({ user }))
class Router extends React.Component {
  render() {
    const { history, user } = this.props
    console.log(this.props)
    return (
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          <ConnectedRouter history={history}>
            <IndexLayout>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => {
                    // return <Redirect to="/pages" />
                    switch (user.role) {
                      case ROLES.superAdmin:
                        return <Redirect to="/pages" />
                      case ROLES.careersAdmin:
                        return <Redirect to="/careers" />
                      case ROLES.appointmentsAdmin:
                        return <Redirect to="/appointments" />
                      default:
                        return <Redirect to="/user/login" />
                    }
                  }}
                />
                {routes.map((route) => {
                  if (route.authorize) return <PrivateRoute key={route.path} {...route} />
                  return (
                    <Route
                      path={route.path}
                      component={route.component}
                      key={route.path}
                      exact={route.exact}
                    />
                  )
                })}
                <Route component={NotFoundPage} />
              </Switch>
            </IndexLayout>
          </ConnectedRouter>
        </Suspense>
      </ErrorBoundary>
    )
  }
}

export default Router
