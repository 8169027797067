/* eslint-disable no-underscore-dangle */
import { notification } from 'antd'
import { timeout } from 'utils'
import { GOOGLE_API_KEY } from 'config'
import fetchA from 'utils/fetch'
import { isEmpty } from '../utils'

function getFormData({ name, priorityOrder, imageTag, location, status, image, comment, showInSuccess }) {
  const formData = new FormData()
  console.log(image)
  if (!isEmpty(image))
    formData.append('image', image.length > 0 ? image[0].originFileObj || null : null)
  formData.append('name', name)
  formData.append('imageTag', imageTag)
  formData.append('priorityOrder', priorityOrder)
  formData.append('location', location)
  formData.append('status', status)
  formData.append('comment', comment)
  formData.append('showInSuccess', showInSuccess)

  return formData
}

export async function createVideo({ videoId, videoTitle }) {
  const body = {
    videoId,
    title: videoTitle,
  }
  const a = await fetchA('/api/videos', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
  if (a) return a
  return null
}

export async function deleteVideo(id) {
  const a = await fetchA(`/api/videos/?videoId=${id}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
  if (a) return a
  return null
}

export async function getVideoList() {
  const a = fetchA('/api/videos')
  if (a) return a
  return null
}

export async function getVideoListMock() {
  const data = [
    {
      id: '59Q_lhgGANc',
      title: 'a',
      key: '1',
    },
    {
      id: '59Q_lhgGANc',
      title: 'b',
      key: '2',
    },
    {
      id: '59Q_lhgGANc',
      title: 'c',
      key: '3',
    },
    {
      id: '59Q_lhgGANc',
      title: 'd',
      key: '4',
    },
    {
      id: '59Q_lhgGANc',
      title: 'e',
      key: '5',
    },
    {
      id: '59Q_lhgGANc',
      title: 'f',
      key: '6',
    },
    {
      id: '59Q_lhgGANc',
      title: 'g',
      key: '7',
    },
    {
      id: '59Q_lhgGANc',
      title: 'h',
      key: '8',
    },
    {
      id: '59Q_lhgGANc',
      title: 'i',
      key: '9',
    },
    {
      id: '59Q_lhgGANc',
      title: 'j',
      key: '10',
    },
  ]
  return timeout(3000).then(() => ({ data }))
}

export const validateVideo = async videoId => {
  console.log('validating video')
  const url = `https://www.googleapis.com/youtube/v3/videos?part=id&id=${videoId}=${GOOGLE_API_KEY}`
  try {
    const response = await fetch(url, { method: 'GET' })
    const responseJSON = await response.json()

    if (!response.ok) {
      const err = new Error(responseJSON.error.message)
      err.status = response.status
      throw err
    } else if (
      Object.prototype.hasOwnProperty.call(responseJSON, "pageInfo'") &&
      responseJSON.pageInfo.totalResults === 1
    ) {
      if (responseJSON.items[0].kind === 'youtube#video') {
        return true
      }
      notification.warning({
        message: 'Invalidnn video ID',
        description: 'Please check the video ID and try again',
      })
      return false
    }
    return null
  } catch (err) {
    console.log(err)
    let msg = `Please check the video ID and try again`
    if (err.error && err.error.message) {
      msg = err.error.message
    } else if (err.message) {
      msg = err.message
    }
    notification.warning({
      message: 'Something went wrong...',
      description: msg,
    })
    return false
  }
}

export async function addCommentForm(values) {
  // console.log('commentForm service', values)

  const formData = getFormData(values)

  const url = '/api/comments'
  const options = {
    method: 'POST',
    body: formData,
  }

  try {
    const response = await fetch(url, options)
    if (!response.ok) {
      const err = new Error(response.statusText)
      err.status = response.status
      throw err
    }
    const responseJSON = await response.json()
    notification.success({
      message: 'Success!',
      description: 'Added successfully',
    })
    // console.log(response, responseJSON)
    if (responseJSON.data) {
      return responseJSON.data
    }
    return null
  } catch (err) {
    // console.log(err)
    notification.error({
      message: err.status,
      description: err.message,
    })
  }
  return null
}

export async function editCommentForm(values) {
  console.log('in edit comment form service', values)

  const formData = getFormData(values)

  let url = `/api/comments/${values._id}`
  if (values.deleteImage) url = `${url}?deleteImage=true`
  console.log(url)
  const options = {
    method: 'PATCH',
    body: formData,
  }
  try {
    const response = await fetch(url, options)
    if (!response.ok) {
      const err = new Error(response.statusText)
      err.status = response.status
      throw err
    }
    const responseJSON = await response.json()
    notification.success({
      message: 'Success!',
      description: 'Edited successfully',
    })
    // console.log(response, responseJSON)
    if (responseJSON.data) {
      return responseJSON.data
    }
    return null
  } catch (err) {
    // console.log(err)
    notification.error({
      message: err.status,
      description: err.message,
    })
  }
  return null
}

export async function uploadComments(formData) {
  const url = `/api/comments/mysql-mongo`
  const options = {
    method: 'POST',
    body: formData,
  }
  try {
    const response = await fetch(url, options)
    if (!response.ok) {
      const err = new Error(response.statusText)
      err.status = response.status
      throw err
    }
    const responseJSON = await response.json()
    // console.log(response, responseJSON)
    if (responseJSON.data) {
      return responseJSON.data
    }
  } catch (err) {
    // console.log(err)
    notification.error({
      message: 'Error!',
      description: err.message,
    })
  }
  return null
}

export async function getComment(id) {
  // console.log('in getComment service id:', id)
  const url = `/api/comments/${id}`
  const options = {
    method: 'GET',
  }
  try {
    const response = await fetch(url, options)
    if (!response.ok) {
      const err = new Error(response.statusText)
      err.status = response.status
      throw err
    }
    const responseJSON = await response.json()
    // console.log(response, responseJSON)
    if (responseJSON.data) {
      return responseJSON.data
    }
  } catch (err) {
    // console.log(err)
    notification.error({
      message: 'Error!',
      description: err.message,
    })
  }
  return null
}
