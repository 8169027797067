import {
  all,
  put,
  call,
  takeEvery,
  // select
} from 'redux-saga/effects'
import { addBlogForm, getBlog, editBlogForm } from 'services/blog'
import actions from './actions'

export function* ADD_BLOG({ payload }) {
  // console.log('in ADD_BLOG saga', payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const data = yield call(addBlogForm, payload)
  // console.log(data)
  if (data) {
    return yield put({
      type: actions.FETCH_SUCCESS,
      payload: data,
    })
  }
  yield put({
    type: actions.FETCH_ERROR,
    payload: 'Error adding blog',
  })
}

export function* EDIT_BLOG({ payload }) {
  // console.log('in EDIT_BLOG saga', payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      success: false
    },
  })
  const data = yield call(editBlogForm, payload)
  // console.log(data)
  if (data) {
    yield put({
      type: actions.FETCH_SUCCESS,
      payload: data,
    })
    yield put({
      type: actions.SET_STATE,
      payload: { redirect: true },
    })
    return
  }
  yield put({
    type: actions.FETCH_ERROR,
    payload: 'Error updating blog',
  })
}

export function* GET_BLOG({ payload }) {
  // console.log('in GET_BLOG saga')
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const data = yield call(getBlog, payload)
  // console.log(data)
  if (data) {
    return yield put({
      type: actions.FETCH_SUCCESS,
      payload: data,
    })
  }
  yield put({
    type: actions.FETCH_ERROR,
    payload: 'Error fetching blog',
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD_BLOG, ADD_BLOG),
    takeEvery(actions.EDIT_BLOG, EDIT_BLOG),
    takeEvery(actions.GET_BLOG, GET_BLOG),
  ])
}
