import {
  all,
  put,
  call,
  takeEvery,
  // select
} from 'redux-saga/effects'
import { addCommentForm, getComment, editCommentForm } from 'services/comment'
import actions from './actions'

export function* ADD_COMMENT({ payload }) {
  // console.log('in ADD_COMMENT saga', payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const data = yield call(addCommentForm, payload)
  // console.log(data)
  if (data) {
    return yield put({
      type: actions.FETCH_SUCCESS,
      payload: data,
    })
  }
  yield put({
    type: actions.FETCH_ERROR,
    payload: 'Error adding comment',
  })
}

export function* EDIT_COMMENT({ payload }) {
  console.log('in EDIT_COMMENT saga', payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const data = yield call(editCommentForm, payload)
  // console.log(data)
  if (data) {
    yield put({
      type: actions.FETCH_SUCCESS,
      payload: data,
    })
    yield put({
      type: actions.SET_STATE,
      payload: { redirect: true },
    })
    return
  }
  yield put({
    type: actions.FETCH_ERROR,
    payload: 'Error updating comment',
  })
}

export function* GET_COMMENT({ payload }) {
  // console.log('in GET_COMMENT saga')
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const data = yield call(getComment, payload)
  // console.log(data)
  if (data) {
    return yield put({
      type: actions.FETCH_SUCCESS,
      payload: data,
    })
  }
  yield put({
    type: actions.FETCH_ERROR,
    payload: 'Error fetching comment',
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD_COMMENT, ADD_COMMENT),
    takeEvery(actions.GET_COMMENT, GET_COMMENT),
    takeEvery(actions.EDIT_COMMENT, EDIT_COMMENT),
  ])
}
